import { __decorate } from "tslib";
import { Component, Vue, Prop, Ref, } from 'vue-property-decorator';
import { NodeType, SourceType, UnitType, } from '../../../typings/organization/org-manage';
import { OrgManageService } from '@/sevices';
import { StaffSelector } from '@cloudpivot-hermes/common-components';
import fileDownlow from '@/common/data-download';
let DeptDetailComp = class DeptDetailComp extends Vue {
    constructor() {
        super(...arguments);
        this.SourceType = SourceType;
        this.isShowImport = false;
        this.isShowExportDeptLoading = false;
        this.exportDeptProgress = 0;
        this.importTitle = '';
        this.uploadApi = OrgManageService.uploadDept;
        this.tempApi = OrgManageService.downloadDeptTemplate;
        // reloadData: Function = ;
        this.uploadParams = {};
        this.NodeType = {
            Org: NodeType.Org,
            Dept: NodeType.Dept,
        };
        this.setManagerLoading = false;
    }
    get tagsList() {
        return this.deptDetail?.tagsList || [];
    }
    get supervisor() {
        const supervisor = [];
        if (this.deptDetail.supervisorId) {
            supervisor.push({
                id: this.deptDetail.supervisorId,
                name: this.deptDetail.supervisorName,
                type: UnitType.User,
            });
        }
        return supervisor;
    }
    get setManagerStaffSelectorParams() {
        return {
            orgId: this.deptDetail.orgId,
        };
    }
    /**
     * 导入部门弹窗
     */
    importDept() {
        this.uploadParams = {
            orgConfigId: this.deptDetail.orgId,
        };
        this.isShowImport = true;
    }
    /**
     * 导出部门
     */
    exportDept() {
        this.exportDeptProgress = 1;
        this.isShowExportDeptLoading = true;
        OrgManageService.exportDept({
            orgConfigId: this.deptDetail.orgId,
        }, (e) => {
            if (e.lengthComputable) {
                const progress = Math.floor((e.loaded / e.total) * 100);
                this.exportDeptProgress = progress;
            }
        }).then(res => {
            // 获取后端返回的文件名
            const headers = res.orgResponse.headers || {};
            const disposition = headers['content-disposition'] || '';
            const unDecodefileName = disposition.split('=')[1] || '';
            const fileStream = res.orgResponse.data;
            const fileType = 'application/octet-stream';
            const fileName = decodeURIComponent(unDecodefileName);
            fileDownlow.downloadByPost(fileStream, fileType, fileName);
            this.exportDeptProgress = 100;
        }).finally(() => {
            setTimeout(() => {
                this.isShowExportDeptLoading = false;
                this.exportDeptProgress = 0;
            }, 500);
        });
    }
    goToOffWork() {
        this.$router.push({
            name: 'ex-serving-officer',
            params: {
                orgId: this.selectedDeptDetail.orgId,
            },
        });
    }
    getValue(deptDetail) {
        return this.$parent.getValue(deptDetail);
    }
    /**
     * 添加子部门
     */
    addChildDept() {
        const addDeptDetail = {
            id: this.selectedDeptDetail.id,
            name: this.selectedDeptDetail.name,
            parentId: this.selectedDeptDetail.parentId,
            orgId: this.selectedDeptDetail.orgId,
        };
        this.$parent.$parent.orgLeft.addChildDept(addDeptDetail);
    }
    reload() {
        window.location.reload();
    }
    /**
     * 编辑部门
     */
    editDept() {
        const editDeptDetail = {
            id: this.selectedDeptDetail.id,
            name: this.selectedDeptDetail.name,
            parentId: this.selectedDeptDetail.parentId,
            orgId: this.selectedDeptDetail.orgId,
        };
        this.$parent.$parent.orgLeft.editDept(editDeptDetail);
    }
    /**
     * 设置主管
     */
    setDeptManager() {
        this.staffSelector.showModal();
    }
    okSetManager(managers) {
        let managerId = '';
        if (managers.length) {
            managerId = managers[0]?.id;
        }
        this.setManagerLoading = true;
        const params = { departmentId: this.deptDetail.id, managerId: managerId };
        OrgManageService.setManager(params).then((res) => {
            if (res.success) {
                return this.$parent.getDeptDetail();
            }
        }).then((deptDetail) => {
            if (deptDetail.id) {
                this.staffSelector.closeModal();
            }
        }).finally(() => {
            this.setManagerLoading = false;
        });
    }
};
__decorate([
    Prop()
], DeptDetailComp.prototype, "selectedDeptDetail", void 0);
__decorate([
    Prop()
], DeptDetailComp.prototype, "deptDetail", void 0);
__decorate([
    Ref()
], DeptDetailComp.prototype, "staffSelector", void 0);
DeptDetailComp = __decorate([
    Component({
        name: 'DeptDetailComp',
        components: {
            StaffSelector,
            Avatar: () => import('./avatar.vue'),
            ImportComp: () => import('./import-comp.vue'),
            DownLoadProgress: () => import('./downLoadProgress.vue'),
        },
    })
], DeptDetailComp);
export default DeptDetailComp;
